/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import {LegacyAddress as Address} from '@signatu/common-react';
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(Address, {
    name: "Signatu",
    line1: "Share Oslo",
    line2: "Myntgata 2",
    city: "Oslo",
    zip: "0151",
    isoCountry: "no"
  }), "\n", React.createElement(_components.p, null, "✉️ ", React.createElement(_components.a, {
    href: "mailto:hello@signatu.com"
  }, "hello@signatu.com")), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "https://twitter.com/@signatucom/"
  }, "@signatucom")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
